import React, { useEffect } from 'react';
import Header from '../UI/Header/Header';
import Hero from './Hero/Hero';
import AboutUs from './AboutUs/AboutUs';
import Newest from './Newest/Newest';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  useEffect(() => {
    // Nastavení overflow-y: hidden při zobrazení stránky
    document.body.style.touchAction = 'pan-y';

    // Vrátíme overflow-y zpět na původní hodnotu při opuštění stránky
    return () => {
      document.body.style.touchAction = 'auto';
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>TACTICAL FORMA - Домашня сторінка</title>
      <meta 
        name="description" 
        content="TACTICAL FORMA — провідний український інтернет-магазин тактичного одягу та спорядження. Якісна продукція для ЗСУ, НПУ та ДСНС. Швидка доставка та надійний сервіс." 
      />
      <meta 
        name="keywords" 
        content="тактичний одяг, ЗСУ, НПУ, ДСНС, тактичне взуття, якісний одяг, інтернет-магазин, доставка Новою Поштою, обмін і повернення" 
      />
      <meta name="author" content="Tactical Forma" />
      <meta property="og:title" content="TACTICAL FORMA - Провідний інтернет-магазин тактичного одягу" />
      <meta 
        property="og:description" 
        content="Обирайте тактичний одяг і спорядження, розроблені для найскладніших умов. Широкий асортимент для ЗСУ, НПУ, та ДСНС. Надійна якість і професійний сервіс." 
      />
      <meta 
        name="twitter:description" 
        content="Широкий асортимент тактичного одягу і спорядження для військових і рятувальників. Якісна продукція з доставкою по Україні." 
      />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="uk" />
      <meta charSet="UTF-8" />
    </Helmet>
    <main>
      <Hero />
      <AboutUs />
      <Newest />
    </main>
    </>
  );
};

export default Home;
