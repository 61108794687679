import React from 'react';
import styles from './AboutUs.module.css';
import aboutUs1 from '../../../assets/images/aboutUs1.jpg';
import aboutUs2 from '../../../assets/images/aboutUs2.jpg';
import aboutUs3 from '../../../assets/images/aboutUs3.jpg';
import { useMediaQuery } from '@uidotdev/usehooks';

const AboutUs = () => {
    const isTablet = useMediaQuery('(min-width: 500px) and (max-width: 700px)');
    const isMobile = useMediaQuery('(max-width: 500px)');

    React.useEffect(() => {
        // Handle smooth scrolling for hash links
        const handleHashChange = () => {
            const hash = window.location.hash.substring(1); // Get the hash without the '#'
            if (hash) {
                const target = document.getElementById(hash);
                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        // Scroll to the section on initial load if there's a hash in the URL
        handleHashChange();

        // Add event listener for hashchange
        window.addEventListener('hashchange', handleHashChange);

        // Cleanup
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return(
        <section className={styles.about}>
            <div className={styles.container}>
                <div className={styles.aboutUs}>
                    <h2 className={styles.title} id="about">Про нас</h2>
                    <p className={styles.description}>
                        Ласкаво просимо на наш сайт TACTICAL FORMA - провідний український інтернет-магазин тактичного одягу та спорядження для ЗСУ, НПУ та ДСНС. Ми пропонуємо широкий асортимент якісного одягу та взуття, розробленого для виконання завдань у найскладніших умовах.
                        <br></br><br></br>
                        У нас Ви знайдете різноманітні моделі, які відрізняються за матеріалами та функціональністю: тактичні куртки, штани, лонгсліви, убакси, термобілизна, гольфи, флісові кофти, футболки, головні убори, рукавиці, бафи, тактичне взуття тощо.
                        <br></br><br></br>
                        Ми працюємо лише з перевіреними матеріалами, щоб гарантувати високу якість та надійність продукції. Наша команда готова допомогти Вам з вибором, враховуючи особливості Вашої служби та індивідуальні потреби.
                        <br></br><br></br>
                        Обирайте одяг, який стане Вашим надійним союзником у виконанні важливих завдань!
                    </p>
                    <h2 className={styles.title} id="payment">Оплата</h2>
                    <p className={styles.description}>Наразі можливі варіанти оплати: <br></br><br></br>
                        Оплата на відділенні (передоплата 200 грн обовʼязкова)<br></br><br></br>
                        Повна оплата онлайн
                    </p>
                    <h2 className={styles.title} id="delivery">Доставка</h2>
                    <p className={styles.description}>Доставка здійснюється транспортною компанією «Нова Пошта»: на відділення, поштомат, адресна доставка курʼєром.<br></br><br></br>
                        Послуги за доставку сплачує отримувач. Відправлення замовлення щодня до 14:00 (окрім неділі), іноді можливі затримки за певними моделями через великий попит.<br></br><br></br>
                        
                    </p>
                    <p className={styles.leftSideContainer}>
                        Умови доставки транспортною компанією «Нова Пошта»: Зверніть увагу! Замовлення зберігаються безкоштовно на відділенні протягом 5 (п’яти) днів, після чого повертаються в Компанію. Отримати посилку може тільки особа, яка зазначена в транспортній декларації в якості одержувача. При собі необхідно обов’язково мати посвідчення особи!<br></br> Замовлення доставляються Новою Поштою протягом 1-2 днів. При отриманні вантажів на відділеннях
                        транспортних компаній дотримуйтеся, будь ласка, чітких правил:<br/> 1. Покупець або
                        його уповноважений представник при отриманні вантажу зобов’язаний звірити кількість
                        отриманих вантажних місць (коробок). Кількість вантажних місць в транспортному документі
                        (накладній, розписці, розпорядженні) має збігатися з фактично отриманою кількістю.<br></br> 2. Після отримання вантажу Покупцеві
                        або його уповноваженому представнику необхідно перевірити непошкодженість товару<br></br> 3. Після виконання дій, зазначених
                        у п. 1 та 2 цієї інструкції, Покупець або його уповноважений представник на відділенні транспортної компанії
                        зобов’язаний розкрити упаковку/тару вантажу та звірити кількість товару на відповідність з товарними документами,
                        доданими до вантажу (перевірка на внутрішню недопоставку), а також оглянути весь товар на
                        наявність пошкоджень незалежно від думки співробітників транспортної компанії.<br></br> УВАГА! Перевіряти вміст і
                        цілісність упаковки тільки на території відділення транспортної компанії.
                    </p>
                    <h2 className={styles.title} id="returns">Обмін та повернення</h2>
                    <p className={styles.description}>У випадку, якщо товар не підійшов - можна оформити обмін/повернення✅<br></br><br></br>

Обмін/повернення можливий протягом 14 календарних днів з дня його отримання в службі доставки.<br></br><br></br>

Обмін/повернення доступно, якщо речі не були у вжитку та упаковані таким же чином, яким надійшли: біркою наперед, в цілісному упакуванні❗️ (перед обміном/поверненням надіслати фото товару в упакуванні менеджеру)</p>
                </div>
                <div className={styles.aboutUsImages}>
                    {!isTablet ? (
                        <>
                            {isMobile ? (
                                <>
                                    <img src={aboutUs1} alt="aboutUs1" className={styles.aboutUsImage} />
                                </>
                            ) : (
                                <>
                                    <img src={aboutUs1} alt="aboutUs1" className={styles.aboutUsImage} />
                                    <img src={aboutUs2} alt="aboutUs2" className={styles.aboutUsImage} />
                                    <img src={aboutUs3} alt="aboutUs3" className={styles.aboutUsImage} />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <img src={aboutUs1} alt="aboutUs1" className={styles.aboutUsImage} />
                            <img src={aboutUs2} alt="aboutUs2" className={styles.aboutUsImage} />
                        </>
                    )}

                </div>
            </div>
        </section>
    );
};

export default AboutUs;
