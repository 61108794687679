import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../types/store.types';
import { getItem, getItemsByCategory, getRelatedItems, unsetSingleItem } from '../../redux/reducers/item-reducer';
import { ProductType } from '../../types/product.types';
import styles from './Product.module.css';
import SkeletonLoader from '../UI/SkeletonLoader/SkeletonLoader';
import { FaPlus, FaMinus } from "react-icons/fa6";
import { ItemsByCategory } from '../../types/item.types';
import Carousel from 'react-multi-carousel';
import { useMediaQuery } from '@uidotdev/usehooks';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import CategoryItem from '../UI/CategoryItem/CategoryItem';
import { getLikedItems } from '../../redux/reducers/liked-reducer';
import { LikedItems } from '../../types/liked.types';
import { IoIosStar, IoIosStarHalf, IoIosStarOutline } from "react-icons/io";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { Rating } from '@mui/material';
import { reviewApi } from '../../api/api';
import { addToCart } from '../../redux/reducers/cart-reducer';
import { SimilarProducts } from '../../types/similar.types';
import Item from '../UI/Item/Item';
import AlertMessage from '../UI/AlertMessage/AlertMessage';
import tactical_logo from '../../assets/images/tactical-forma-logo.jpg'
import { Helmet } from 'react-helmet-async';
interface ProductProps {
  singleItem: ProductType;
  getItem: (id: number) => void;
  isFetching: boolean;
  getItemsByCategory: (categoryId: number, page: number) => void;
  categoryItems: ItemsByCategory;
  likedItems: LikedItems;
  isLikedFetching: boolean;
  getLikedItems: () => void;
  unsetSingleItem: () => void;
  addToCart: (product_id: number, color: string, size: string, quantity: number) => void;
  getRelatedItems: (id: number) => void;
  similarProducts: SimilarProducts;
}

const gap = 25;

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1200 }, items: 3 },
  tablet: { breakpoint: { max: 1200, min: 768 }, items: 2 },
  mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
};
export const colorMap: { [key: string]: string } = {
  Red: '#e96f6f',
  Black: '#000000',
  Blue: '#0000FF',
  Green: '#008000',
  Yellow: '#FFFF00',
  White: '#FFFFFF',
  Gray: '#808080',
  Purple: '#800080',
};

const Product = (props: ProductProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const is500Px = useMediaQuery('(min-width: 401px) and (max-width: 500px)');
  const is400px = useMediaQuery('(max-width: 400px)');
  let itemWidth = is500Px ? 300 : is400px ? 240 : 370;
  const { id } = useParams();
  const carouselRef = useRef<Carousel>(null);
  const isDesktop = useMediaQuery('(min-width: 1238px)');
  const isTablet = useMediaQuery('(min-width: 850px) and (max-width: 1237px)');
  const itemsToShow = isDesktop ? 3 : isTablet ? 2 : 1;
  const containerWidth = itemWidth * itemsToShow + gap * (itemsToShow - 1);
  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedMedia, setSelectedMedia] = useState<any | null>(null);
  const [showMore, setShowMore] = useState(false);
  const [reviewRating, setReviewRating] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [review, setReview] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const reviewFormRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isIos, setIsIos] = useState(false);


  const productName = props.singleItem?.name || 'Neznámý produkt'; // Pokud by název nebyl k dispozici
  const pageTitle = `TACTICAL FORMA - ${productName}`;

  useEffect(() => {
    const checkDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor;
      const isIosDevice =
        /iPad|iPhone|iPod/i.test(userAgent) && !(window as any).MSStream;
      setIsIos(isIosDevice);
    };

    checkDevice(); // Initial check

    // Recheck on resize (optional, depending on your requirements)
    window.addEventListener("resize", checkDevice);

    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  const openModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };
  // Validation state
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    review: '',
    rating: '',
  });
  const validateInputs = () => {
    let validationErrors = { name: '', email: '', review: '', rating: '' };
    let isValid = true;

    if (!name.trim()) {
      validationErrors.name = "Ім'я не може бути порожнім.";
      isValid = false;
    }
    if (!email.trim()) {
      validationErrors.email = 'Електронна пошта не може бути порожньою.';
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validationErrors.email = 'Невірний формат електронної пошти.';
      isValid = false;
    }
    if (!review.trim()) {
      validationErrors.review = 'Відгук не може бути порожнім.';
      isValid = false;
    }
    if (reviewRating === 0) {
      validationErrors.rating = 'Оцініть, будь ласка.';
      isValid = false;
    }

    setErrors(validationErrors);
    return isValid;
  };

  const handleAddReview = async () => {
    if (validateInputs()) {
      try {
        await reviewApi.addReview(Number(id), review, reviewRating, name, email);

        // Clear the form fields after successful submission
        setName('');
        setEmail('');
        setReview('');
        setReviewRating(0);

        // Show success alert and reset error messages
        setAlertMessage('Відгук успішно додано!');
        setErrors({ name: '', email: '', review: '', rating: '' });

        // Refresh the page or reload reviews
        props.getItem(Number(id));
      } catch (error) {
        console.error("Error adding review:", error);
        setAlertMessage("Помилка під час додавання відгуку.");
      }
    }
  };




  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => setAlertMessage(null), 3000); // Hide alert after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [alertMessage]);


  const handleRatingChange = (rating: number) => {
    setReviewRating(rating);
    setErrors({ ...errors, rating: '' });
  };


  useEffect(() => {
    if (id) {
      props.getItem(Number(id));
    }
    props.getLikedItems();
    props.getRelatedItems(Number(id));
    setSelectedColor(null);
    setSelectedMedia(null);
    return () => {
      props.unsetSingleItem();
    };
  }, [id]);


  useEffect(() => {
    if (!props.isFetching && props.singleItem?.inventory.length && !selectedColor) {
      // Nastavení barvy
      setSelectedColor(props.singleItem.inventory[0].color);

      // Získání položek podle kategorie
      props.getItemsByCategory(props.singleItem.category_id, 1);

      // Scroll na review sekci
      if (window.location.hash === "#review" && reviewFormRef.current) {
        reviewFormRef.current.scrollIntoView({ behavior: "smooth" });
      }

      // Video logika
      const video = videoRef.current;
      if (video) {
        const playAndPause = () => {
          video.play();
          setTimeout(() => {
            video.pause();
          }, 100); // Po 100 ms video zastaví
        };

        // Spustí přehrání a pauzu po načtení videa
        video.addEventListener("loadeddata", playAndPause);

        // Vyčištění event listeneru při odpojení komponenty
        return () => {
          video.removeEventListener("loadeddata", playAndPause);
        };
      }
    }
  }, [props.singleItem, props.isFetching, selectedColor]);


  useEffect(() => {
    if (selectedColor && currentImages.length > 0) {
      setSelectedMedia(currentImages[0]);
    }
  }, [selectedColor, props.singleItem]);

  const handleColorChange = (color: string) => {

    setSelectedColor(color);
    setSelectedSize('');
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleMediaClick = (media: any) => {
    setSelectedMedia(media);
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
    setErrors({ ...errors, name: '' });
  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: '' });
  };
  const handleReviewChange = (e: any) => {
    setReview(e.target.value);
    setErrors({ ...errors, review: '' });
  };

  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(<IoIosStar key={`full-${i}`} className={styles.star} />);
    }
    if (hasHalfStar) {
      stars.push(<IoIosStarHalf key="half" className={styles.star} />);
    }
    const emptyStarsCount = 5 - stars.length;
    for (let i = 0; i < emptyStarsCount; i++) {
      stars.push(<IoIosStarOutline key={`empty-${i}`} className={styles.star} />);
    }
    return stars;
  };

  let currentImages = Object.entries(props.singleItem.media)
    .filter(([key]) => {
      const [_, color] = key.split('_'); // Rozdělení podle '_', druhý prvek je barva
      return color === selectedColor
    })
    .map(([_, media]) => media)
    .flat()

  let allMediaExceptCurrent = Object.entries(props.singleItem.media)
    .filter(([key]) => {
      const [_, color] = key.split('_');
      return color.includes(selectedColor || ''); // Zahrnuje obrázky pro aktuální barvu nebo other images
    })
    .map(([_, media]) => media)
    .flat()
    .filter(media => media !== selectedMedia); // Filtrace aktuálního média (pokud je potřeba)

  let sizeTableImage = Object.entries(props.singleItem.media)
    .filter(([key]) => {
      const [_, color] = key.split('_');
      return color === '' || color === 'null'// Zahrnuje obrázky pro aktuální barvu nebo other images
    })
    .map(([_, media]) => media)
    .flat()
    .filter(media => media !== selectedMedia); // Filtrace aktuálního média (pokud je potřeba)
  const currentSizes = props.singleItem.inventory.find(item => item.color === selectedColor)?.sizes || [];
  const likedIds = props.likedItems.favorites.map(item => item.product_id);

  if (props.isFetching && props.isLikedFetching) {
    return <SkeletonLoader length={10} />;
  }

  const addQuantity = () => {
    setQuantity(quantity + 1);
  }
  const subtractQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }
  const onBuyClick = async () => {
    if (!selectedSize) {
      alert('Будь ласка, виберіть розмір');
      return;
    }
    try {
      await props.addToCart(Number(id), selectedColor || 'Black', selectedSize, quantity);
      setSuccessMessage('Товар успішно додано до кошика');
      setIsSuccessModalOpen(true);

      setTimeout(() => {
        setIsSuccessModalOpen(false);
      }, 3000);

    } catch (error) {
      console.error('Error adding to cart:', error);
      alert('Помилка під час додавання товару до кошика');
    }
  }


  return (
    <main className={styles.main}>
      <Helmet>
        <title>{`TACTICAL FORMA - ${productName}`}</title>
        <meta
          name="description"
          content={`Купити ${productName} на Tactical Forma. Високоякісні товари для вашого комфорту та безпеки. Швидка доставка та вигідні ціни. Замовляйте ${productName} онлайн з доставкою по Україні та отримайте найкращу якість!`}
        />
        <meta
          name="keywords"
          content={`${productName}, Tactical Forma, купити ${productName}, ${productName} онлайн, високоякісне ${productName}, військове спорядження, ${productName} для військових, ${productName} для тактичних тренувань, ${productName} Україна`}
        />
      </Helmet>

      <section className={styles.productContainer}>
        {isSuccessModalOpen && (<AlertMessage message={successMessage || ''} />)}
        <div className={styles.imagesContainer}>
          <div className={styles.thumbnailContainer}>
            {allMediaExceptCurrent.map((media: any, index: number) => (
              <div key={index} className={styles.thumbnailWrapper} onClick={() => handleMediaClick(media)}>
                {media.media_type === 'image' ? (
                  <img src={media.media_url} alt="Product thumbnail" className={styles.thumbnail} />
                ) : (
                  <div className={styles.videoWrapper}>
                    <video
                      src={media.media_url}
                      className={styles.thumbnail}
                      preload="auto"
                      muted
                      playsInline
                      poster={isIos ? tactical_logo : ''}
                    />
                    <div className={styles.playIcon}>▶</div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {selectedMedia && (
            <div className={styles.mainImageContainer}>
              {selectedMedia.media_type === 'image' ? (
                <img src={selectedMedia.media_url} alt={`Product in ${selectedColor}`} className={styles.mainImage} />
              ) : (
                <video src={selectedMedia.media_url} controls className={styles.mainImage} autoPlay ref={videoRef} />
              )}
            </div>
          )}


        </div>

        <div className={styles.productInfo}>
          <h2 className={styles.title}>{props.singleItem.name}</h2>
          {props.singleItem.description.split('\n').map((paragraph, index) => (
            <p key={index} className={styles.description}>{paragraph}<br></br></p>
          ))}

          {/* Color Selector */}
          <div className={styles.colorSelector}>
            <p className={styles.colorText}>Колір:</p>
            {props.singleItem.inventory.map((item) => (
              <div
                key={item.color}
                className={`${styles.colorContainer} ${selectedColor === item.color ? styles.activeColorContainer : ''}`}
              >
                <p>{item.color_hex}</p>
                <button
                  onClick={() => handleColorChange(item.color)}
                  className={styles.colorButton}

                >{item.color}</button>
              </div>
            ))}
          </div>
          <div>
            <p className={styles.sizeTitle}>Розмірна сітка</p>
            <div className={styles.imageGrid}>
              {sizeTableImage.map((media: any, index) => (
                <img
                  key={index}
                  src={media.media_url}
                  alt={`Image ${index + 1}`}
                  className={styles.image}
                  onClick={() => openModal(media.media_url)}
                />
              ))}
            </div>

            {isOpen && selectedImage && (
              <div className={styles.modal} onClick={closeModal}>
                <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                  <img src={selectedImage} alt="Selected" className={styles.modalImage} />
                  <button className={styles.closeButton} onClick={closeModal}>
                    ×
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* Size Selector */}
          <div className={styles.sizeSelector}>
            <p className={styles.sizeText}>Величина:</p>
            {currentSizes.map((size, index) => (
              <button
                key={index}
                className={styles.sizeButton}
                disabled={size.stock === 0 || selectedSize === size.size}
                onClick={() => setSelectedSize(size.size)}
                style={{ backgroundColor: selectedSize === size.size ? '#999999' : '#CED4DA', color: selectedSize === size.size ? 'white' : 'black' }}
              >
                {size.size} {size.stock === 0 && 'Немає в наявності'}
              </button>
            ))}
          </div>

          {/* Buy Section */}
          <div className={styles.buyContainer}>
            <div className={styles.countButtons}>
              <FaMinus className={styles.countButton} onClick={subtractQuantity} />
              <p className={styles.count}>{quantity}</p>
              <FaPlus className={styles.countButton} onClick={addQuantity} />
            </div>
            <button className={styles.buyButton} onClick={onBuyClick}>Купити</button>
          </div>
        </div>

      </section>



      <section className={styles.reviews} id="review">
        <h2 className={styles.reviewTitle}>Відгуки</h2>
        <div className={styles.reviewsContent}>
          <div>
            <div className={styles.reviewContainer}>
              {showMore ? props.singleItem.reviews.map((review, index) => (
                <div key={index} className={styles.review}>
                  <p className={styles.reviewText}>{review.review_text}</p>
                  <div className={styles.reviewInfo}>
                    <p className={styles.reviewer}>{review.reviewer_name}</p>
                    {
                      review.star_rating && (
                        <div className={styles.rating}>
                          {renderStars(Number(review.star_rating))} {/* Call the renderStars function */}
                        </div>
                      )
                    }
                  </div>
                </div>
              )) : props.singleItem.reviews.slice(0, 2).map((review, index) => (
                <div key={index} className={styles.review}>
                  <p className={styles.reviewText}>{review.review_text}</p>
                  <div className={styles.reviewInfo}>
                    <p className={styles.reviewer}>{review.reviewer_name}</p>
                    {
                      review.star_rating && (
                        <div className={styles.rating}>
                          {renderStars(Number(review.star_rating))} {/* Call the renderStars function */}
                        </div>
                      )
                    }
                  </div>
                </div>
              ))}


            </div>
            {
              props.singleItem.reviews.length > 2 && (
                <div className={styles.showMoreButton} onClick={handleShowMore}>
                  {showMore ? 'Приховати' : 'Показати більше'}
                  {showMore ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              )
            }
          </div>
          <div className={styles.addReviewAndAlert}>
            {alertMessage && <div className={styles.alert}>{alertMessage}</div>}

            <div className={styles.addReview} >

              <label htmlFor="name" className={styles.reviewLabel}>Ім'я</label>
              <input
                type="text"
                id="name"
                className={styles.reviewInput}
                placeholder="Ваше ім'я"
                value={name}
                onChange={handleNameChange}
              />
              <div ref={reviewFormRef}></div>
              {errors.name && <p className={styles.errorText}>{errors.name}</p>}

              <label htmlFor="email" className={styles.reviewLabel} >Імейл</label>
              <input

                type="email"
                id="email"
                className={styles.reviewInput}
                placeholder="Ваш імейл"
                value={email}
                onChange={handleEmailChange}
              />
              {errors.email && <p className={styles.errorText}>{errors.email}</p>}

              <label htmlFor="review" className={styles.reviewLabel}>Відгук</label>
              <textarea
                id="review"
                className={styles.reviewTextArea}
                placeholder="Ваш відгук"
                rows={5}
                value={review}
                onChange={handleReviewChange}
              />
              {errors.review && <p className={styles.errorText}>{errors.review}</p>}

              <div className={styles.ratingContainer} >
                <p className={styles.ratingLabel}>Оцінка:</p>
                <Rating
                  name="rating"
                  value={reviewRating}
                  precision={0.5}
                  onChange={(event, newValue) => handleRatingChange(newValue ?? 0)}
                  style={{ fontSize: 30, color: '#FFCC00' }}
                />
                {errors.rating && <p className={styles.errorText}>{errors.rating}</p>}
              </div>

              <div className={styles.addReviewButtonContainer} >
                <button className={styles.addReviewButton} onClick={handleAddReview}>Додати</button>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className={styles.sameSlider} >
        <p className={styles.SliderTitle} >Подібні товари</p>
        <div className={styles.carouselContainer} style={{ width: `${containerWidth}px` }}>
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            infinite={true}
            itemClass={styles.carouselItem}
            arrows={false}
          >
            {props.similarProducts.products.map((item) => (
              <Item key={item.product_id} item={item} />
            ))}
          </Carousel>

          {/* Custom Navigation Buttons */}
          <button className={styles.arrow} onClick={() => carouselRef.current?.previous(1)} aria-label="Previous">
            <FaChevronLeft />
          </button>
          <button className={styles.arrow} onClick={() => carouselRef.current?.next(1)} aria-label="Next">
            <FaChevronRight />
          </button>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = (state: RootState) => ({
  singleItem: state.item.singleItem,
  isFetching: state.item.isFetching,
  categoryItems: state.item.categoryItems,
  likedItems: state.liked.likedItems,
  isLikedFetching: state.liked.isFetching,
  similarProducts: state.item.similarProducts,
});

const mapDispatchToProps = {
  getItem,
  getItemsByCategory,
  getLikedItems,
  unsetSingleItem,
  addToCart,
  getRelatedItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
