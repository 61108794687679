import React, { useState, useEffect } from "react";
import { CSSProperties } from "react";

const CookieWarning = () => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowWarning(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowWarning(false);
  };

  const handleDecline = () => {
    window.location.href = "https://www.google.com";
  };

  if (!showWarning) {
    return null; // Повертає null, якщо попередження не потрібно показувати
  }

  return (
    <div style={overlayStyle}>
      <div style={popupStyle}>
        <p style={textStyle}>
          Користуючись нашим вебсайтом, ви погоджуєтесь на використання файлів cookie, які необхідні для його роботи. 
          Продовжуючи користуватись сайтом, ви приймаєте нашу політику cookie.
        </p>
        <div style={buttonContainerStyle}>
          <button onClick={handleDecline} style={declineButtonStyle}>
            ← Вийти з сайту
          </button>
          <button onClick={handleAccept} style={acceptButtonStyle}>
            Погоджуюсь
          </button>
        </div>
      </div>
    </div>
  );
};

// Стилі залишаються такими ж, як і в попередньому коді
const overlayStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupStyle: CSSProperties = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "12px",
  textAlign: "center",
  maxWidth: "500px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  fontFamily: "Arial, sans-serif",
};

const textStyle: CSSProperties = {
  fontSize: "16px",
  color: "#555",
  marginBottom: "20px",
  lineHeight: "1.5",
};

const buttonContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const declineButtonStyle: CSSProperties = {
  backgroundColor: "transparent",
  color: "#555",
  border: "none",
  fontSize: "14px",
  cursor: "pointer",
  textDecoration: "underline",
};

const acceptButtonStyle: CSSProperties = {
  padding: "10px 20px",
  backgroundColor: "#E0E0E0",
  color: "#000",
  border: "none",
  borderRadius: "20px",
  fontSize: "14px",
  cursor: "pointer",
};

export default CookieWarning;
